@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.uploadModal {
  > div {
    min-width: 50vw !important;
  }
}
.uploadFailModal {
  > div {
    min-width: 80vw !important;
  }
}

.uploadFlexContainer {
  display: flex;
  justify-content: center;
}

.uploadModalTitle {
  padding-right: 40px !important;
  border-bottom: none !important;
  > button {
    height: 50px;
  }
}

.modalBody {
  padding-left: 50px !important;
  padding-right: 50px !important;
  padding-top: 0px !important;
  text-align: left;

  h5 {
    font-weight: 600;
  }
}

.modalBodyContainer {
  text-align: center;
  display: flex;
  justify-content: center;
  gap: 2em;
}

.modalBodyIconTick,
.modalBodyIconCross {
  width: 150px;
  border: 2px solid rgb(214, 214, 214);
  padding: 40px 35px;
  border-radius: 10px;
  margin: 20px;
}

.modalBodyIconTick:hover {
  border: 2px solid rgba(0, 255, 0, 0.145);
  background-color: rgba(0, 255, 0, 0.145);
  cursor: pointer;
}

.modalBodyIconCross:hover {
  border: 2px solid rgba(255, 0, 0, 0.122);
  background-color: rgba(255, 0, 0, 0.122);
  cursor: pointer;
}

.uploadInputParent {
  text-align: center;
  border: 3px dashed rgb(210, 227, 244);
  padding: 1.5rem;
  position: relative;
  cursor: pointer;
}
.uploadInput {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  cursor: pointer;
}

.uploadIcon {
  width: 75px;
  height: 75px;
  color: $primary-color;
  margin: 20px;
}

.selectedTitle {
  text-align: left;
  padding-left: 30px;
  font-weight: 600;
}

.flex {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1em;
  width: 100%;

  p {
    margin-bottom: 0 !important;
  }
}

.pass {
  font-size: 1.2em;
  font-weight: 600;
  color: $valid-color;
}

.expiry {
  width: 70%;
}

.thDescription {
  width: 60%;
}

.td {
  width: 20%;
  height: 50px;
  padding: 0 !important;
}

.input {
  border: none;
  height: 50px;
  margin: 0 !important;
  // padding: 0 !important;
}
.input:focus {
  border: 2px solid $primary-color;
  border-radius: 5px;
  margin: 0 !important;
  padding: 10px;
  // padding: 0 !important;
}

.priceContainer {
  float: right;
  button {
    float: right;
  }

  .price {
    display: flex;
    gap: 1em;
    justify-content: end;
    align-items: start;
    margin-bottom: 30px;
    p,
    h3 {
      margin-bottom: 0;
    }
    p {
      color: $valid-color;
      font-size: 3em;
      font-weight: 600;
    }
  }
  button {
    margin-top: 30px;
  }
}

.rowBtnGroup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
  margin-bottom: 30px;
}

.timeTaken {
  display: flex;
  gap: 1em;
  padding-left: 10%;

  input {
    width: 10%;
    padding-left:auto;
    text-align: center;
  height: 2.2em;
  font-size: 1.2em;
  }
  select {
  width: 50%;
  height: 2.2em;
  font-size: 1.2em;
  }

  option {
  font-size: 1.2em !important;
  }


}
