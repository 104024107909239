// --------------- variables --------------------
$primary-color: #00adb5; 
$primary-color-hover: #00acb554;
$secondary-color: #001524;
$tertiary-color:  #d7fcff;
$quaternary-color: #0a0074;
$quinary-color: #d5573b;
$senary-color: #0c3137;
$valid-color: rgb(0, 156, 0);
$error-color: red;

$white: #fff;
$black: #000000;

$border: 2px solid black;

$xx-small-font-size: 0.9rem;
$x-small-font-size: 1.1rem;
$small-font-size: 1.2rem;
$base-font-size: 1.3rem;
$medium-font-size: 1.2rem;
$large-font-size: 1.8rem;
$base-line-height: 1.5rem;

$h1-font-size: 2.2rem;
$h1-font-size-mobile: 2rem;
$h2-font-size: 1.8rem;
$h2-font-size-mobile: 1.6rem;
$h3-font-size: 1.6rem;
$h4-font-size: 1.4rem;

$compact-button-height: 26px;
$button-height: 40px;
$button-font-size: 1.4rem;
$button-line-height: 4rem;

$border-radius-small: 4px;
$border-radius-medium: 6px;
$border-radius: 50px;
$animation-duration: 0.6s;

$box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
$box-shadow-large: 1px 1px 4px 1px #00acb5a3;
$box-shadow-large-grey: 0px 7px 10px rgba(73, 74, 77, 0.509);
$box-shadow-small-grey: 0px 1px 4px rgba(118, 130, 150, 0.33);
$box-shadow-medium-grey: 0px 4px 4px rgba(118, 130, 150, 0.33);
$box-shadow-large-white: 0px 1px 4px rgba(255, 255, 255, 0.882);

/* Breakpoints */
$small-screen-min-width: 390px; // iPhone 8
$small-wide-screen-min-width: 500px;

$medium-screen-min-width: 769px;
$medium-wide-screen-min-width: 850px; // arbitrary horizontal mobile width
$large-screen-min-width: 1024px;
$site-container-width: 1200px;

$offset-top: calc(2em + 75px);
