@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.extraInfo {
  @include medium {
    padding: 30px 50px;
  }
}

.heading {
  color: $primary-color;
  font-size: 0.9em;
  margin-bottom: 0px;
  @include medium {
      font-size: 1.3em;
    margin-bottom: 7px
  }
}

.headingGroup,
.cancelled {
  margin-bottom: 10px;
  @include medium {
    margin-bottom: 30px;
  }
}

.paid {
  font-weight: 600;
  margin-bottom: 20px;
  color: $valid-color;
}

.cancelled {
  width: 100px;
  color: $error-color;
  border-radius: 5px;
  font-weight: 600;
}
