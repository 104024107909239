@import "../core/breakpoints.scss";

.topSpacer {
  height: 90px;
  
  @include small-wide {
    height: 110px;

  }
  
  @include medium {
    height: 170px;

  }
}

.bottomSpacer {
  height: 80px;
}


.midSpacer {
  height: 40px;
}
