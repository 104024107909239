@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.searchBar {
  position: absolute;
  top: 185px;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  @include small-wide {
    top: 230px;
    width: 80%;
  }
  @include medium {
    width: 80%;
  }
  @include large {
    width: 50%;
  }
}

.searchBarSpacer {
  height: 95px;
  @include small-wide {
    height: 120px;
  }
}

.spacer {
  height: 40px;
  @include small-wide {
    height: 64px;
  }
}

.servicesHeading {
  text-align: left;
  width: 65%;
  margin: auto auto 10px;

  > h2 {
    margin-left: 1%;
  }

  @include medium {
    width: 75%;
  }
}

.productsContainer {
  margin: 20px auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 5%;
  height: 100%;
  padding: 0%;
  max-width: 80%;

  .tagline {
    font-size: 1em;
  }
}

.productCard {
  outline: 1px solid rgb(199, 199, 199);
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  width: 260px;
  height: 100%;
  text-align: left;
  > h4,
  p {
    padding-left: 16px;
    padding-right: 16px;
    margin-top: 5px;
  }

  > h4 {
    font-size: 1.25em;
  }

  > p {
    font-size: 0.9em;
  }

  > button {
    margin: auto;
    margin-bottom: 15px;
  }
}

.productCard:hover {
  outline: 2px solid $primary-color;
}

.productImg {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  width: 100%;
  height: 170px;
  background-size: cover;
  margin-bottom: 10px;
}

.link {
  text-decoration: none;
  color: black;
  margin-bottom: 20px;
}

.link:hover {
  text-decoration: none;
  color: black;
}

.button {
  border: 1px solid $primary-color;
  background-color: $primary-color;
  color: white;
  border-radius: 30px;
  padding: 2px 40px;
  box-sizing: content-box;
  font-size: 1.2em;

  @include medium {
    padding: 8px 60px;
    font-size: 1.3em;
  }
}

.button:hover {
  background-color: $quinary-color;
  border-color: $quinary-color;
}

.divider {
  border-bottom: 1px solid $primary-color;
  width: 80%;
  margin: -5px auto 5px;
}
.headingDivider {
  border-bottom: 2px solid $primary-color;
  width: 50%;
  margin-left: 0;
}
