@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.postcodeLookup [type="button"]:not(:disabled),
.postcodeLookup [type="reset"]:not(:disabled),
.postcodeLookup [type="submit"]:not(:disabled),
.postcodeLookup button:not(:disabled) {
  border: 1px solid $primary-color;
  background-color: $primary-color !important;
  color: white;
  border-radius: 30px;
  font-size: 0.8em;
  padding: 5px 10px;
  box-sizing: content-box;
  display: block;
  margin: -10px auto 20px;

  @include medium {
    font-size: 1.2em;
    padding: 5px 15px;
  }
}

.postcodeLookup [type="button"]:not(:disabled):hover,
.postcodeLookup [type="reset"]:not(:disabled):hover,
.postcodeLookup [type="submit"]:not(:disabled),
.postcodeLookup button:not(:disabled):hover {
  background-color: $quinary-color !important;
  border-color: $quinary-color;
}

.postcodeLookup select {
  margin-bottom: 30px !important;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0.5px solid black;
  background-color: transparent;
  width: 80%;
  padding: 5px;
  margin: auto;
}

.coverageArea {
  font-size: 0.8em;
  color: $valid-color;
}

.notCoverageArea {
  font-size: 0.8em;
  color: $error-color;
}

.heading {
  text-align: left;
  font-weight: 600;
  margin: 50px auto 10px 5px;
}

.divider {
  border-bottom: 2px solid $primary-color;
  width: 60%;
  margin: 1px 15px 20px 5px;
  @include medium {
    width: 50%;
  }
}

.address {
  margin-top: 30px;
}

.address label,
.address input {
  text-align: left;
  width: 100% !important;
}

.addressInstruction {
  text-align: left;
  margin-top: 20px;
}

.postcodeLookupInput {
  margin-bottom: 0 !important;
}

.postcodeResults {
  background-color: white;
  position: absolute;
  width: 80%;
  border: 1px solid rgb(221, 221, 221);
  // margin-top: -30px;
  text-align: left;
  max-height: 300px;
  overflow-x: auto;
  overflow-y: auto;
  font-size: 0.8em;
  margin: auto;
  padding: 0px 10px 10px;
}

.result {
  margin: 0 !important;
  padding: 5px;
  display: flex;
  width: 100%;
  gap: 0.8em;
  align-items: top;
}

.icon {
  margin-top: 4px;
}

.result:hover {
  background-color: $primary-color;
  color: white;
  cursor: pointer;
}


.inputContainer {
width: 100%;
}

.spacer {
border-bottom: 1px solid rgb(221, 221, 221);
width: 100%;
}

.allRadios {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.accessRadioBtns {
  display: flex;
  align-items: center;
  justify-content: start;
  text-align: left;
  margin: 0;
  width: 140px;

  @include medium {
    width: 170px;
  }
}

.accessRadioBtns  {
  > label {
    margin: 0 !important;
  }

  >input[type=radio] {
    width:20px !important;
    margin: 0 10px 0 0 !important;
  }
}