@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.bookingCard {
  text-align: left;
  // background-color: $tertiary-color;
  margin: 15px auto;
  border-radius: 5px;
  padding: 15px 5px;
  border-left: 10px solid $primary-color;
  box-shadow: $box-shadow-large-grey;
}

.bookingCard:hover {
  cursor: pointer;
}

.booking {
  display: flex;
  align-items: center;
  justify-content: space-between;
  div > p {
    margin-bottom: 0 !important;
  }
}

.column1 {
  p {
    overflow-wrap: break-word;
    margin-bottom: 2px;
    width: 70px;
  }
}

.downArrow {
  width: 50px;
  height: 45px;
}

.upArrow {
  width: 50px;
  height: 30px;
  padding: 0px 10px;
}

.name,
.service,
.time {
  width: 40%;
  text-align: center;

  @include small-wide {
    width: 15%;
  }
}

.address {
  text-align: center;
  width: 30%;
  line-height: 1.5em;
}


.FAIL {
  font-weight: 700;
  p {
    color: $error-color;
  }
}

.PASS {
  font-weight: 700;
  p {
    color: $valid-color;
  }
}