@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.status {
  font-size: 1em;
  font-weight: 600;
}
.pass {
  color: $valid-color;
  font-size: 1.3em;
}
.fail {
  color: $error-color;
}

.button {
  margin: auto;
  width: fit-content;
  margin-bottom: 10px;
}
