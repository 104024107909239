@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.dateTimeContainer {
  width: 100%;
  justify-content: space-between;
  margin-bottom: 30px;

  div input {
    margin-bottom: 0px;
    width: 99%;
  }
}

.dateInput input::placeholder {
  color: black;
}

.dateTimeContainer button {
background: none;
border: none;
}

.dateTimeContainer button:hover {
  background-color: $primary-color;
  color: white;
  cursor: pointer;
  font-weight: 600;
}
.dateTimeContainer button:active {
  background-color: $primary-color-hover;
  color: white;
}

.timeInput, .dateInput {
  margin-bottom: 2px !important;
}

.timeslotContainer select {
width: 100%;
padding: 5px;
border: none;
border-bottom: 1px solid black;
}

button:disabled {
  background: none;
}

.errorMessage {
  text-align: left;
  margin-left: 10%;
  margin-top: -20px;
  font-size: 0.8em;
  color: $error-color;
}

.heading {
  text-align: left;
  font-weight: 600;
  margin: 20px auto 10px 5px;
}
.divider {
  border-bottom: 2px solid $primary-color;
  width: 60%;
  margin: 1px 15px 20px 5px;
  @include medium {
    width: 50%;
  }
}

.slotSelected {
  background-color: $primary-color;
  color: white;
}

.list{
  text-align: left;
  box-shadow: $box-shadow-large;
  padding: 10px;
}