@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.banner {
  background-image: url("../assets/shutterstock_1956528430.jpg");
  background-repeat: no-repeat;
  background-position: 80%;
  background-size: cover;
  width: 100%;
  height: 38vh;

  @include medium {
    background-position: 80%;
    height: 70vh;
  }

  @include large {
    background-position: left;
  }
}

.title {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  margin: 110px 5%;
  color: black;
  text-align: left;
  font-size: 1.3em;
  font-weight: 600;
  min-width: 240px;
  @include small-wide {
    font-size: 1.5em;
  }

  @include medium {
    font-size: 2.5em;
    margin: 240px 5%;
    min-width: 60%;
    white-space: nowrap;
  }

  @include large {
    font-size: 3.5em;
    margin: 220px 8%;
  }
}

.bannerDescription {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  margin: 150px 5% !important;
  color: black;
  text-align: left;
  font-size: 0.8em;
  font-weight: 600;
  width: 290px;
  @include small-wide {
    font-size: 1em;
    font-weight: 500;
  }

  @include medium {
    font-size: 1.5em;
    font-weight: normal;
    margin: 320px 5% !important;
    width: 50%;
    line-height: 1.45em;
  }
  @include large {
    margin: 310px 8% !important;
    width: 47.5%;
  }
}

.readMore {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  margin: 200px 5% !important;
  @include small-wide {
    margin: 280px 5% !important;
  }

  @include medium {
    font-weight: normal;
    margin: 430px 5% !important;
  }
  @include large {
    font-weight: normal;
    margin: 390px 8% !important;
  }
}
