@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.modalBody {
  text-align: left;

  > h2 {
    font-size: 1.3em;
  }
}

.heading {
  text-align: left;
  font-weight: 600;
  margin: 0px auto 10px 5px;
}

.title {
  text-align: left;
  font-size: 1em !important;
}

.divider {
  border-bottom: 2px solid $primary-color;
  width: 60%;
  margin: 1px 15px 20px 5px;
  @include medium {
    width: 50%;
  }
}

.redirect {
  text-align: left;
  font-size: 0.8em;
  font-style: italic;
  margin-bottom: 15px !important;
}

.loadingBtn {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loadingBtn div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid white;
  border-radius: 50%;
  animation: loadingBtn 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primary-color transparent transparent transparent;
}
.loadingBtn div:nth-child(1) {
  animation-delay: -0.45s;
}
.loadingBtn div:nth-child(2) {
  animation-delay: -0.3s;
}
.loadingBtn div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loadingBtn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.closeBtns {
  justify-content: start !important;
  @include medium {
    justify-content: flex-end !important;
  }
}

.arrowHead {
  width: 50px;
  height: 50px;
  margin-top: -13px;
}

.flex {
  display: flex;
  gap: 1em;

}

.toastPosition {
  top: 100px !important;
  width: 70% !important;
}
