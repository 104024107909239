@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.flexContainer {
  display: flex;
  flex-direction: column;
  @include medium {
    flex-direction: row;
  }

  .getInTouch {
    display: flex;
    flex-direction: column;
    min-width: 40%;
    margin: auto;
  }

  .getInTouch h1 {
    margin-top: 10%;
    margin-bottom: 30px;

    @include medium {
      margin-top: 0%;
    }
  }

  .getInTouch button {
    margin: auto;
    margin-bottom: 10px;

    @include large;
  }

  .anchor {
    text-decoration: none;
    color: white;
  }

  .anchor:hover {
    text-decoration: none;
    color: white;
  }
}
