@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.icon {
  margin-top: 90px;
  width: 100px;
  height: 100px;
  
  @include small-wide {
    margin-top: 130px;

  }
}

.title {
  margin: 30px auto 10px;
}

.container {
  width: 80%;
  margin: auto;

  @include large {
    width: 60%;
  }
}

.bookingDetails {
  width: 100%;
  margin: 0 auto 20px;
  text-align: center;
  @include medium {
    width: 80%;
  }

  table {
    text-align: left;

    width: 100%;
    margin: 20px auto;
    border-collapse: collapse;
    @include large {
      width: 75%;
    }

    td {
      border: 1px solid rgb(84, 84, 84);
      padding: 5px;
      vertical-align: top;
    }

    tr td:first-child {
      width: 30%;
    }
  }
}
