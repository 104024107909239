@import "../core/variables.scss";
@import "../core/core.scss";

.button, .modalBtn {
  border: 1px solid $primary-color !important;
  background-color: $primary-color !important;
  color: white;
  border-radius: 30px !important;
  font-size: 1.1em;
  padding: 5px 10px;
  box-sizing: content-box;
  width: fit-content;

  @include medium {
    font-size: 1.3em;
    padding: 5px 15px;
  }
}

.button:hover,  .modalBtn:hover {
  background-color: $quinary-color !important;
  border-color: $quinary-color !important;
}

.top {
  height: 30px;
}

.secondaryBtn {
  border: 1px solid $primary-color !important;
  background-color:white;
  color: $primary-color;
  border-radius: 30px !important;
  font-size: 1.1em;
  padding: 5px 10px;
  box-sizing: content-box;
  width: fit-content;
  
  @include medium {
    font-size: 1.3em;
    padding: 5px 15px;
  }
  
}
.secondaryBtn:hover {
  background-color: 1px solid $primary-color !important;
  color: white;
  
}

.modalBtn {
border-radius: 5px !important;
// width: 120px;
}