@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.container {
  width: 80%;
  margin: auto;
  @include medium {
    width: 95%;
  }
  @include large {
    width: 70%;
  }

  h1 {
    margin: 25px auto 20px;

    @include large {
      margin: 50px auto 50px;
      font-weight: 600;
    }
  }

  p {
    width: 90%;
    font-size: 0.8em;
    margin: 0 auto;

    @include medium {
      width: 90%;
      font-size: 1em;
      text-align: left;
    }
  }

  img {
    width: 100%;
    margin: auto;
    margin-bottom: 30px;
    border-right: 6px solid $primary-color;

    @include medium {
      width: 50%;
      margin-bottom: 0px;
    }
  }

  button {
    margin: 20px;
    border-radius: 0px;
    font-size: 1em;
  }

  .divider {
    border-bottom: 2px solid $primary-color;
    width: 100%;
    margin: 30px auto;
    @include medium {
      width: 70%;
    }
    @include large {
      width: 40%;
    }
  }
}

.flexContainer {
  @include medium {
    display: flex;
    align-items: center;
  }
}
