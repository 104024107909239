@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.formContainer {
  margin-top: -40px;
  display: flex;
  flex-direction: column;
  padding-top: 40px;
  padding-bottom: 80px;
  background-color: $senary-color;
  color: white;
  margin-bottom: 50px;
  form {
    width: 80%;
    margin: auto;

    > label {
      color: white;
    }

    @include large {
      width: 60%;
    }
  }

  h2 {
    width: 90%;
    margin: 10px auto 40px;
    font-weight: 600;
  }

  .input {
    color: white;
    border-color: white;
    margin-bottom: 30px !important;
    margin-left: 0 !important;
  }

  
  .button {
    width: fit-content;
    border: 1px solid $primary-color;
    background-color: $primary-color;
    color: white;
    border-radius: 30px;
    font-size: 0.8em;
    padding: 5px 15px;
    margin: auto;

    @include medium {
      font-size: 1.2em;
      padding: 5px 15px;
    }
    @include large {
      font-size: 1.5em;
    }
  }

  .button:hover {
    background-color: $quinary-color;
    border-color: $quinary-color;
  }

  .submitted {
    width: 80%;
    color: white;
    font-size: 15px;
    margin: auto;
    // text-align: left;
    padding-bottom: 20px;
  }
}

.loadingBtn {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loadingBtn div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: loadingBtn 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primary-color transparent transparent transparent;
}
.loadingBtn div:nth-child(1) {
  animation-delay: -0.45s;
}
.loadingBtn div:nth-child(2) {
  animation-delay: -0.3s;
}
.loadingBtn div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loadingBtn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
