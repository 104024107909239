@import "../core/variables.scss";
@import "../core/breakpoints.scss";


.container {
    text-align: center;
}
.year {
    font-weight: 600;
    font-size: 1.1em;
    color: $primary-color;
}