@import "../core/breakpoints.scss";
@import "../core/variables.scss";

.postcodeChecker {
  margin: auto;
  background-color: $primary-color;
  margin-top: 20px;
  z-index: 10;
  padding-top: 5px;
  padding-bottom: 5px;
  width: 100%;
  position: relative;
  > p {
    color: white;
    margin-bottom: 5px !important;
  }

  > div > div > input {
    border-color: white;
    border-radius: 5px;
    background-color: white;
    color: black;
    margin-bottom: 0 !important;
    padding-left: 10px;
    width: 100%;
  }
  > input::placeholder {
    font-size: 0.9em;
  }

  @include medium {
    text-align: left;
    padding: 10px 25px 15px;

    > input {
      padding: 10px;
    }
  }
  
  
  .pcCoverageArea {
    width: 80%;
    text-align: right;
    font-size: 1em;
    > a {
      color: white;
    }
    > a:hover {
      font-weight: 600;
    }
    
    @include medium {
      font-size: 19px;
      text-align: right;
      > a {
        font-size: 19px !important;
      }
    }
  }
  
  .postcodeLookupInput {
    margin-bottom: 0 !important;
  }
  
  .postcodeResults {
    background-color: white;
    position: absolute;
    width: 100%;
    border: 1px solid rgb(221, 221, 221);
    text-align: left;
    max-height: 300px;
    overflow-x: auto;
    overflow-y: auto;
    font-size: 0.8em;
    margin: auto;
    padding: 0px 10px 10px;
    
  }
  
  .result {
    margin: 0 !important;
    padding: 5px;
    display: flex;
    width: 100%;
    gap: 0.8em;
    align-items: top;
  }

  .icon {
    margin-top: 4px;
  }

  .result:hover {
    background-color: $primary-color;
    color: white;
    cursor: pointer;
  }

  .inputContainer {
    margin: auto auto 10px;
    position: relative;
    width: 80%;
    @include medium {
      max-width: 60%;
    }
    @include large {
      max-width: 40%;
    }
  }

  .instructions {
    text-align: left;
    width: 80%;
    margin: auto;

    @include medium {
      max-width: 60%;
    }

    @include large {
      max-width: 40%;
    }
  }
}
