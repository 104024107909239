@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.spacer {
  height: 59px;

  @include small-wide {
    height: 81px;
  }
  @include medium {
    height: 85px;
  }
}

.postcodeLink {
  color: $senary-color !important;
}

.banner {
  // background-image: url("../assets/homepagebanner.jpg");
  // background-repeat: no-repeat;
  // background-position: center;
  // background-size: cover;
  position: relative;
  width: 100%;
  height: 300px;

  @include medium {
    height: 410px;
  }

  @include large {
    height: 500px;
  }
}

.textContainer {
  // border: 2px solid black;
  position: absolute;
  top: 20px;
  left: 0;
  right: 0;
  margin: auto;
  width: 80%;
  text-align: left;

  > h1 {
    font-size: 1.3em;
    font-weight: 600;
  }

  > p {
    font-size: 1em;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  ul.checkList {
    padding-left: 11px;
    > li {
      font-size: 0.8em;
      margin: 10px 0;
      list-style: none;
      background-image: url("../assets/tick-svgrepo-com.svg");
      background-repeat: no-repeat;
      background-position: left top;
      background-size: 20px;
      padding-left: 25px;
    }
  }

  @include small-wide {
    top: 30px;
    max-width: 550px;
  }

  @include medium {
    top: 40px;
    max-width: 850px;

    ul.checkList {
      padding-left: 25px;
    }
    > h1 {
      font-size: 2em;
      font-weight: 600;
    }

    > p {
      font-size: 1.3em;
      line-height: 1.4em;
      margin-top: 15px;
      margin-bottom: 15px;
    }

    ul.checkList {
      > li {
        font-size: 1em;
        margin: 10px 0;
        background-position: left;
        background-size: 20px;
        padding-left: 30px;
      }
    }
  }

  @include large {
    > h1 {
      font-size: 2.5em;
      font-weight: 600;
    }

    > p {
      font-size: 1.7em;
      line-height: 1.4em;
      margin-top: 20px;
      margin-bottom: 20px;
    }

    ul.checkList {
      > li {
        font-size: 1.2em;
        margin: 10px 0;
        background-position: left;
        background-size: 20px;
        padding-left: 30px;
      }
    }
  }
}

.overlay {
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgb(255, 255, 255);
  background: linear-gradient(
    360deg,
    $tertiary-color 0%,
    rgba(255, 254, 254, 0.883) 50%,
    rgb(255, 255, 255) 100%
  );
}

.electrician {
  display: none;
  @include small-wide {
    display: block;
    z-index: 13;
    position: absolute;
    top: 110px;
    left: 70%;
    width: 130px;
    height: auto;
  }

  @include medium {
    display: block;
    z-index: 13;
    position: absolute;
    top: 140px;
    left: 70%;
    width: 180px;
    height: auto;
  }

  @include large {
    display: block;
    position: absolute;
    top: 170px;
    left: 60%;
    width: auto;
  }
}

.reviewContainer {
  margin: 20px;
  padding: 10px;
  height: 440px;
  @include medium {
    height: 460px;
  }
  
  .reviewiFrame {
    height: 405px;
    @include medium {
      height: 420px;
    }
  }
}
