@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.container {
  max-width: 80%;
  margin: auto;
  @include medium {
    width: 75%;
  }
  .input {
    margin: 0;
    width: 100%;
  }
}

.heading {
  text-align: left;
  font-weight: 600;
  margin: 30px auto 10px;
}

.availability {
  text-align: left;
  font-size: 1em;
  font-weight: 600;
  @include medium {
    font-size: 1.2em;
  }
}

.description {
  text-align: left;
  line-height: 1.6em;
  margin-bottom: 20px;
}

label {
  text-align: left;
  margin-bottom: 10px;
  margin-left: 0;
  width: 100%;
}

.leftAlign {
  text-align: left;
}

.totalPrice {
  font-weight: 600;
  text-align: center;
  width: 80%;
  margin: auto auto 20px;
}

.bookBtn {
  margin-bottom: 30px;
}

.servicePriceImg {
  width: 100%;
  position: relative;
  bottom: 0;
  float: bottom;
}

.imgContainer {
  width: 100%;
  height: 30vh;
  overflow: hidden;
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;
  border-bottom: 5px solid $primary-color;

  @include medium {
    height: 45vh;
  }
}
.divider {
  border-bottom: 2px solid $primary-color;
  width: 60%;
  margin: 1px 15px 20px 0px;
  @include medium {
    width: 50%;
  }
}
.list {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5em;
}

.list .priceLabel {
  font-size: 1.3em;
  font-weight: 600;
}

.bold {
  font-weight: 600;
  font-size: 1.3em;
}

.radioBtns,
.applianceRadioBtns {
  display: flex;
  align-items: center;
  text-align: left;
  margin: 0;
  width: 60px;
}

.applianceRadioBtns {
  width: fit-content;
  padding-right: 12px;
  
  @include medium {
    width: 100px;
    
  }
  
  > input {
    width: 15px;
  }
  
  >label {
    padding-left: 5px;
    width: fit-content;
  }
}

.radioBtns label,
.radioBtns input[type="radio"],
.propertyRadioBtns label,
.propertyRadioBtns input[type="radio"],
.nonDomBtns label,
.applianceRadioBtns label,
.applianceRadioBtns input[type="radio"],
.minorWorksRadioBtns label,
.minorWorksRadioBtns input[type="radio"] {
  margin: 0 !important;
}

.propertyRadioBtns input[type="radio"],
.nonDomBtns input[type="radio"] {
  margin: 0 10px 0 0 !important;
}

.propertyRadioBtns,
.nonDomBtns {
  display: flex;
  justify-content: start;
  align-items: center;
  text-align: left;
  margin: 0 !important;
  width: 150px;

  @include medium {
    width: 170px;
  }
}

.nonDomBtns input[type="radio"],
.propertyRadioBtns input[type="radio"] {
  border: $border;
  margin: 0 10px 0 0 !important;
  width: 20px !important;
}

.nonDomBtns {
  padding-bottom: 10px;
}

.nonDomRadios {
  margin-bottom: 20px;
}

.minorWorksRadioBtns {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.minorWorksRadioBtns input[type="radio"] {
  width: 50px;
}

.allRadios {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

.button {
  border: 1px solid $primary-color !important;
  background-color: $primary-color !important;
  color: white;
  border-radius: 30px !important;
  font-size: 1.1em;
  padding: 5px 10px;
  box-sizing: content-box;
  width: fit-content;
  
  @include medium {
    font-size: 1.3em;
    padding: 5px 15px;
  }
}

.button:hover {
  background-color: $quinary-color !important;
  border-color: $quinary-color !important;
}

.loadingBtn {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loadingBtn div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #fff;
  border-radius: 50%;
  animation: loadingBtn 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #00adb5 transparent transparent transparent;
}
.loadingBtn div:nth-child(1) {
  animation-delay: -0.45s;
}
.loadingBtn div:nth-child(2) {
  animation-delay: -0.3s;
}
.loadingBtn div:nth-child(3) {
  animation-delay: -0.15s;
}

.price {
  text-align: right;
  font-size: 1.7em;
  font-weight: 600;
  color: $valid-color;
}
.estimatedPrice {
  text-align: right;
  font-size: 1.5em;
  font-weight: 600;
}

.redirect {
  font-size: 0.7em;
  font-style: italic;
  margin-bottom: 0;
}

@include large {
  .flex {
    display: flex;
    gap: 10%;
  }
}

.flexItem2,
.flexItem1 {
  width: 100%;
}

.numberInput {
  width: 40px;
  margin-bottom: 0 !important;
  border: 1px solid rgb(195, 195, 195) !important;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
}

.numInputLabel,
.numInputLabelPlate {
  margin-bottom: 0 !important;
  padding-left: 20px;
}

.numInputLabelPlate {
  vertical-align: bottom;
}

.circuitOutletRadioBtns {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}

.decNumInputBtn,
.incNumInputBtn {
  border: none;
  border-radius: 5px;
  background-color: $primary-color;
  color: white;
  width: 30px;
  height: 25px;
  text-align: center;
}

.decNumInputBtn {
  margin-right: 10px;
}

.incNumInputBtn {
  margin-left: 10px;
}

.decNumInputBtn:hover,
.incNumInputBtn:hover {
  cursor: pointer;
}
.decNumInputBtn:active,
.incNumInputBtn:active {
  background-color: white;
  border: 1px solid rgb(195, 195, 195);
  color: white;
}

/* Chrome, Safari, Edge, Opera */
.numberInput::-webkit-outer-spin-button,
.numberInput::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.numberInput[type="number"] {
  appearance: textfield;
}

.checkBoxOptions {
  display: flex;
  margin-top: 5px;
  > input {
    width: 20px;
    border: $border;
    margin: 0 !important;
  }

  > label {
    margin: 0 !important;
    padding-left: 15px;
    margin-top: 1px !important;
  }
}

.spacer {
  height: 20px;

  @include medium{

  }
}

@keyframes loadingBtn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
