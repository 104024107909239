@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.servicesContainer {
  display: flex;
  justify-content: center;
  gap: 0.8em;
  margin: 15px 10px 25px;
  @include small-wide {
    gap: 2em;
  }
  @include medium {
    gap: 5em;
  }
  @include large {
    gap: 4em;
  }

  .serviceExampleCard {
    // border: $border;
    width: 130px;
    @include small-wide {
      width: 200px;
      height: 300px;
    }
    @include large {
      width: 290px;
      height: 400px;
    }
  }
  .serviceExampleCard:hover {
    cursor: pointer;
  }

  .divider {
    border-bottom: 3px solid $primary-color;
    width: 20%;
    margin: 15px 15px 20px;
  }

  .serviceExampleContainer {
    display: flex;
    @include large {
      margin-bottom: 40px;
    }
  }

  .img {
    border-bottom: 1px solid $primary-color;
    width: 70px;
    
    @include small-wide {
      border-bottom: 2px solid $primary-color;
      width: 150px;
    }
    
    @include large {
      width: 200px;
      
    }
  }

  .serviceName {
    margin: 20px auto 10px;
    font-weight: 600;
    font-size: 0.8em;
    
    @include small-wide {
      font-size: 1.1em;

    }
    @include large {
      font-size: 1.5em;
    }
  }

  .serviceDescription {
    font-size: 0.7em;
    margin: auto 5px 10px !important;
    @include small-wide {
      font-size: 0.9em;
    }
    @include medium {
      font-size: 1.2em;
    }
  }

  button {
    width: 50%;
    font-size: 0.65em;
    width: 60%;
    
    @include small-wide {
      font-size: 0.8em;
    }
    
    @include large {
      font-size: 1.2em;
      width: 60%;
    }
  }

}
