@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.dashboard {
  width: 85%;
  margin: auto;

  @include small-wide {
    width: 60%;
  }
  @include medium {
    width: 50%;
  }
  @include large {
    width: 35%;
  }
}

.toastPosition {
  top: 55px !important;
}

.bookingCard {
  text-align: left;
  // border: $border;
  // background-color: $tertiary-color;
  margin: 15px auto;
  border-radius: 5px;
  padding: 10px;
  padding-top: 15px6;
  border-left: 10px solid $primary-color;
  box-shadow: $box-shadow-medium-grey;
}

.bookingCard:hover {
  cursor: pointer;
}

.booking {
  display: flex;
  align-items: center;
  justify-content: start;
  gap: 1em !important;
}

.column1 {
  p {
    overflow-wrap: break-word;
    margin-bottom: 0 !important;
    width: 70px;
  }
}

.column2 {
  text-align: left;
  width: 100%;
}

.extraInfo {
  margin: 10px auto 10px;
  padding: 0px 15px;
  > p {
    text-align: left;
    font-size: 0.85em;
  }
  > button {
    margin-left: 15%;
  }

  > .btnGroup {
    display: flex;
    gap: 0.8em;
    justify-content: center;
    > button {
      font-size: 0.8em;
      width: 105px;

      @include medium {
        width: 130px;
      }
    }

    > button:hover {
      background-color: $quinary-color !important;
      border-color: $quinary-color !important;
    }
  }
}

.cancelled,
.cancelledSelected {
  width: 100px;
  color: $error-color;
  // padding: 3px 6px;
  border-radius: 5px;
  margin-top: 10px !important;
  font-size: 0.9rem;
  font-weight: 600;
}

.complete,
.completeSelected {
  width: 100px;
  color: $valid-color;
  border-radius: 5px;
  margin-top: 10px !important;
  font-size: 0.9rem;
  font-weight: 600;
}

.valid,
.nextSelected {
  width: 100px;
  text-align: center;
  background-color: $valid-color;
  color: white;
  padding: 3px 6px;
  border-radius: 5px;
  margin: auto;
  margin-top: 10px !important;
  font-size: 0.9rem;
}

.cancelledSelected,
.nextSelected,
.completeSelected {
  font-size: 1.1em;
  width: 80%;
  text-align: center;
  margin: auto;
  color: white;
}

.completeSelected,
.nextSelected {
  background-color: $valid-color;
}

.cancelledSelected {
  background-color: $error-color;
}

.cancelledConfirmation {
  text-align: center !important;
  font-style: italic;
  margin-bottom: 0 !important;
  font-size: 0.9rem !important;
}

.bookingTitle,
.paid {
  font-size: 1em !important;
  font-weight: 600;
  // text-align: center!important;
  margin-bottom: 0;
}

.paid {
  color: $valid-color;
  font-size: 1em;
}

.address {
  font-size: 1em !important;
  margin-bottom: 5px !important;
}

.time {
  font-weight: 600;
  font-size: 1.2em !important;
  margin-bottom: 10px !important;
}

.bold {
  font-weight: 600;
}

.linkGroups {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 20px 0px;
  a {
    color: black;
  }

  a:hover {
    color: $primary-color;
  }

  img {
    width: 20px;
  }
}

.left {
  text-align: left;
}

.downArrow {
  width: 25px;
  height: 25px;
}

.inline {
  p,
  img {
    margin-bottom: 0;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5em;
}

.divider {
  border-bottom: 2px solid rgb(214, 214, 214);
  width: 95%;
  margin: 15px auto;
}

.heading {
  color: $primary-color;
  margin-bottom: 5px;
}

.quoteHeader {
  margin-top: 35px;
  color: $primary-color;
  font-weight: 600;
}

.quoteBreakdownTable {
  font-size: 0.8em !important;
}
