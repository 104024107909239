@import "../core/variables.scss";
@import "../core/breakpoints.scss";

@keyframes slideIn {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

.nav {
  box-shadow: $box-shadow-small-grey;
  position: fixed;
  top: -1px;
  left: -1px;
  width: 101vw;
  height: 60px;
  z-index: 100;
  background-color: white;

  @include small-wide {
    height: 85px;
    padding: 0px 100px 0px 100px;
  }

  > div {
    @include small-wide {
      display: flex;
      justify-content: space-between;
      gap: 1em;
      align-items: center;
      height: 100%;
    }

    @include medium {
      justify-content: space-evenly;
    }

    .notExpanded {
      display: none;

      @include medium {
        display: flex;
        align-items: center;
        gap: 0.9em;
        font-size: 0.9em;
      }

      @include large {
        gap: 2em;
      }
    }
  }

  .logo {
    width: 210px;
    position: absolute;
    top: 10px;
    left: 20px;
    padding-right: 1.5rem;
    z-index: 100;

    @include small-wide {
      position: absolute;
      width: 280px;
      top: 15px;
      left: 5%;
    }
    @include medium {
      position: static;
      width: 315px;
      top: 10px;
      left: 5%;
    }
  }

  .logo:hover {
    cursor: pointer;
  }

  a {
    color: $secondary-color;
    text-decoration: none;
    font-size: 1em;

    @include small-wide {
      font-size: 1.2em;
      white-space: nowrap;
    }
  }

  a:hover {
    color: $primary-color;
  }
}

.hamburger {
  // removes default border on button element
  border: 0;
  height: 60px;
  width: 55px;
  padding: 0.5rem;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  // positions the icon to the right and center aligns it vertically
  position: absolute;
  top: 48%;
  right: 0;
  transform: translateY(-50%);
  margin-right: 30px;

  @include small-wide {
    right: 3%;
    width: 70px;
    height: 80px;
  }

  @include medium {
    display: none;
  }
}

.linkExpanded {
  font-size: 0.9em !important;
  font-weight: 600;
  text-align: left;
  padding-left: 20px;
  width: 100%;

  @include medium {
    font-size: 1.5rem;
    font-weight: normal;
    text-align: center;
  }
}

.linkGroup,
.serviceLinkGroupMobile,
.loginLinkGroup {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}

.linkGroup img,
.serviceLinkGroupMobile img,
.loginLinkGroup img {
  width: 35px;
  height: 35px;
  margin-left: 15px;
}

.loginLinkGroup {
  @include medium {
    margin-left: -25px;
  }

  > a {
    color: $primary-color !important;
    font-weight: 600;
  }
}

.serviceLinkGroupMobile {
  margin-bottom: 0;
  > p {
    margin: 0;
  }
  > p:hover {
    cursor: pointer;
    color: $primary-color;
  }

  @include medium {
    display: none;
  }
}

.serviceLinkGroupMedium {
  display: none;

  @include medium {
    display: block;
  }
}

.services {
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 0.7em;
  padding-left: 30px;
  margin-bottom: 30px;
  margin-top: 10px;

  @include small {
    padding-left: 13%;
    margin-top: 20px;
  }

  @include medium {
    display: none;
  }
}

.services img {
  width: 15px;
  margin-left: 5px;
}

.serviceLink {
  margin-top: 5px;
  margin-bottom: 5px;
}

.expanded {
  display: flex;
  flex-direction: column;
  background-color: white;
  position: fixed;
  top: 0px;
  right: 0px;
  width: 70%;
  height: 100vh;
  padding-top: 70px;
  animation: slideIn 0.3s ease-in-out;
  box-shadow: none;

  &:active {
    box-shadow: none;
    outline: none;
  }

  @include small-wide {
    top: 0px;
    padding-top: 90px;
  }
  @include medium {
    top: 85px;
    padding-top: 20px;
  }
}

.displayNone {
  display: none;
}

.blur {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: rgba(255, 255, 255, 0.278);
  top: 0;
  left: 0;
  z-index: 0;
  backdrop-filter: blur(2px);
}

.cross {
  height: 50px;
  width: 50px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 45%;
  right: 1%;
  transform: translateY(-50%);
  margin-right: 30px;

  &:active {
    background-color: $primary-color-hover;
  }

  @include small-wide {
    right: 3%;
    width: 70px;
    height: 70px;
  }

  @include medium {
    display: none;
  }
}

.accountIcon {
  width: 45px;
}
.accountIcon:hover {
  cursor: pointer;
}

.loginLinkAvatar {
  @include medium {
    display: flex;
    align-items: center;
  }
}

.userBtnContainer {
  margin-left: 20px !important;

  img {
    margin-left: -1px !important;
    margin-top: -1px !important;

    @include medium {
      margin-left: 0px !important;
      margin-top: 0px !important;
    }
  }

  @include medium {
    margin-left: 0px !important;
  }
}

.userName {
  display: flex;
  align-items: center;
  @include medium {
    gap: 10px;
  }

  a {
    width: 200px;
    text-align: left;
    margin-bottom: 0 !important;
    // border: $border;
  }
}
