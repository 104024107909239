@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.footer {
  position: absolute;
  bottom: 0;
  width: 100vw;
  margin-top: 50px !important;
  margin-bottom: 15px;
  a {
    font-size: 9px;
    text-decoration: none;
    color: $senary-color;
  }

  @include medium {
    a {
      font-size: 12px;
    }
  }
}
