@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.dashboard {
  width: 85%;
  margin: auto;

  @include small-wide {
    width: 40%;
  }
}
.container {
  display: flex;
  justify-content: center;
  gap: 8em;
}

.adminOptions {
  background-color: $primary-color;
  padding: 40px;
  border-radius: 10px;
  > p {
    font-size: 2em;
    font-weight: 600;
    color: white;
  }
}
.adminOptions:hover {
  background-color: $primary-color-hover;
  cursor: pointer;
}

.flexContainer {
  display: flex;
  justify-content: space-between;
  .column2 {
    width: 78%;
    padding-right: 60px;
  }
}

.table {
  td {
    padding-top: 20px !important;
    padding-bottom: 20px !important;
    vertical-align: middle;
  }

  thead {
    text-align: center;
  }
}

.th {
  width: 15%;
}

.toastPosition {
  top: 100px !important;
  right: 50px !important;
}

