@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.bannerContainer, .emergencyBanner {
  font-size: 0.9em;
  background-color: $senary-color;
  color: white;
  display: flex;
  gap: 0.3em;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  h3 {
    font-size: 0.8em;
    margin-top: auto;
    margin-bottom: auto;
  }
  
  @include small-wide {
    font-size: 1.3em;
  }
  
  @include medium {
    h3 {
      font-size: 1.1em;
      padding-top: 15px;
      padding-bottom: 15px;
    }
    button {
      font-size: 1.1em;
    }
  }
  
  @include large {
    display: flex;
    gap: 0.8em;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 1.65em;
  }
  
  .anchor {
    text-decoration: none;
    color: white;
  }
  
  .anchor:hover {
    text-decoration: none;
    color: white;
  }
}

.emergencyBanner {
  font-size: 0.8em;
    @include small {
    font-size: 1.1em;
  }
  @include medium {
    font-size: 1.3em;
  }
  @include large {
    font-size: 1.7em;
  }
}
