@import "../core/variables.scss";
@import "../core/core.scss";

.strong {
  font-weight: 600;
}

.status {
  margin: 30px auto !important;
  font-size: 1.5em;
}

.fail {
  font-weight: 600;
  font-size: 2em;
  color: $error-color;
  
}

.bookingInfo {
  margin: 20px auto 40px;
  table {
    font-size: 1.2em;
    margin: auto;
  }
}

.thDescription {
  width: 65%;
}

.th,
.thDescription {
  font-weight: 600;
  border: 1px solid black;
  padding: 5px 10px !important;
}

.td {
  font-weight: 500;
  width: 25%;
  height: 50px;
  padding: 0px 15px !important;
  border: 1px solid black;
  line-height: 2em;
}

.priceContainer {
  // margin-right: 0;
  width: 300px;
  float: right;

  button {
    float: right;
  }

  .price {
    display: flex;
    gap: 1em;
    align-items: start;
    justify-content: end;

    p,
    h4 {
      margin-bottom: 0;
    }

    p {
      color: $valid-color;
      font-size: 2.2em;
      font-weight: 600;
    }
  }
  button {
    margin-top: 30px;
  }
}
