@import "../core/variables.scss";
@import "../core/breakpoints.scss";

.wrapperBelow {
  background-color: $error-color;
  border-radius: 100px;
  padding: 1px 5px 1px 2px;
  width: fit-content;
  display: flex;
  margin-top: -20px;
  margin-bottom: 20px;
  @include medium {
    justify-content: center;
    align-items: center;
    padding: 0px 5px 0px 2px;
  }
}

.msgBelow {
  margin: auto;
  font-size: 0.5em;
  color: white;
  text-align: left;
}

.wrapperAbove {
  background-color: $error-color;
  border-radius: 100px;
  padding: 1px 5px 1px 2px;
  width: fit-content;
  display: flex;
  @include medium {
    justify-content: center;
    align-items: center;
    padding: 0px 5px 0px 2px;
  }
}

.msgAbove {
  margin: auto;
  font-size: 0.5em;
  color: white;
  text-align: left;
}

.errorIco {
  width: 50px;
}
