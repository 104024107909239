@import "./variables.scss";
@import "./breakpoints.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;500;600;900&display=swap");
// ------------------- globals ------------------
html,
body {
  display: block;
  width: 100vw;
  max-width: 100%;
  box-sizing: border-box;
  background-color: $white;
  margin: 0 auto;
  color: $secondary-color;
  font-weight: 400;
  line-height: 1;
  height: 100%;
  text-align: center;

  * {
    box-sizing: border-box;
    font-family: "Montserrat", sans-serif;
  }
}
#root {
  position: relative;
  height: auto;
  min-height: 100vh;
}

h1 {
  color: black;
  font-size: $h1-font-size;
}

p {
  font-size: $xx-small-font-size;
  color: $secondary-color;

  @include medium {
    font-size: $medium-font-size;
    line-height: $base-line-height;
  }
}
label {
  font-size: $xx-small-font-size;
  color: $secondary-color;

  @include medium {
    font-size: $small-font-size;
    line-height: $base-line-height;
  }
}

input,
textarea {
  margin-bottom: 30px !important;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 0.5px solid black;
  background-color: transparent;
  width: 100%;
  padding: 5px;
  margin: auto;
}

input:focus,
textarea:focus {
  outline: none;
  background-color: transparent;
  border-bottom: 2px solid black;
}

.error {
  font-size: 0.8em;
  color: $error-color;
  margin-top: 10px;
}
.valid {
  font-size: 0.9em;
  color: $valid-color;
  margin-top: 10px;
}

.inputError {
  font-size: 0.8em;
  color: $error-color;
  margin-top: -2 0px;
  text-align: left;
}

input:disabled {
  background-color: rgb(216, 215, 215);
}

input::placeholder, textarea::placeholder {
  color: rgb(187, 187, 187);
  font-weight: 400;
}

.button {
  border: 1px solid $primary-color !important;
  background-color: $primary-color !important;
  color: white;
  border-radius: 30px !important;
  font-size: 0.8em;
  padding: 5px 10px;
  box-sizing: content-box;
  // width: fit-content;
  
  @include medium {
    font-size: 1em;
    padding: 5px 15px;
  }
}

.button:hover {
  background-color: $quinary-color !important;
  border-color: $quinary-color !important;
}

.loadingBtn {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.loadingBtn div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid white;
  border-radius: 50%;
  animation: loadingBtn 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: $primary-color transparent transparent transparent;
}
.loadingBtn div:nth-child(1) {
  animation-delay: -0.45s;
}
.loadingBtn div:nth-child(2) {
  animation-delay: -0.3s;
}
.loadingBtn div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes loadingBtn {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.uploadBtn {
  background-color: $valid-color !important;
  border: $valid-color !important;
  width: 120px;
}
.editBtn, .secondaryBtn {
  width: 120px;
}

.secondaryBtn {
  background-color: white !important;
  border: 1px solid $primary-color !important;
  color: $primary-color !important;
  margin-top: 10px;
}
.secondaryBtn:hover {
  color:  white !important;
  margin-top: 10px;
}

