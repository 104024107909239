@import "../core/variables.scss";
@import "../core/breakpoints.scss";



.bookingSearchContainer {
  margin: auto;
}
.searchContainer, .bookingSearchContainer  {
  width: 100%;
position: relative;
  @include small-wide {
    width: 74%;
  }
  @include large {
    width: 65%;
    
  }
  }

.searchInput {
  // border-left: 1px solid rgba(168, 168, 168, 0.615);
  background-image: url('../assets/search.svg');
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 20px;
  border-bottom: 1px solid rgba(168, 168, 168, 0.615);
  background-color: white;
  border-radius: 0.25rem;
  height: 40px;
  padding-left: 50px;
  box-shadow: $box-shadow-large-grey;
  margin-bottom: 0 !important;
  @include medium {
    height: 50px;
  }
}

.searchInput::placeholder {
  color: rgb(86, 85, 85);
}

.searchinput:not(:focus) {
  .searchResults {
    visibility: hidden;
  }
}

.searchInput:focus {
  background-color: white;
  border: none;
}

.searchResults {
  background-color: white;
  position: absolute;
  width: 100%;
  border: 1px solid rgb(221, 221, 221);
  text-align: left;
  max-height: 300px;
  overflow-x: auto;
  overflow-y: auto;
  margin: auto;
  z-index: 99;
}

.searchResult {
  padding: 10px 10px 10px 20px;

  > a {
    color: black;
    text-decoration: none;
  }
}

.searchResult:hover {
  background-color: $primary-color;
  > a {
    color: white !important;
    text-decoration: none;
  }
}

.searchResult:active {
  background-color: $primary-color;
  > a {
    color: white;
    text-decoration: none;
  }
}

> input {
  max-width: 500px;
  margin-top: 20px;
}

> h1 {
  font-size: 2.2em;
  font-weight: 600;
}

> p {
  font-size: 1.3em;
  letter-spacing: 2px;
  margin-top: 15px;
  margin-bottom: 10px;
}

ul.checkList {
  margin-top: 30px;
  > li {
    font-size: 1em;
  }
}
